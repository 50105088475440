/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react"
// import { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import { HeaderAnim } from "../components/animscripts/page-header-anim";

import LinkedinIcon from '../images/linkedin-icon.svg'
import Navbar from "./navigation/Navbar"
import "./layout.css"

const Cursor = () => {
  useEffect(()=>{
    document.addEventListener('mousemove', handleMousePos);
  })
  const handleMousePos = (e) => {
    const CURSOR = document.querySelector('.mouse-cursor');
    const HOVER = document.querySelectorAll('.cursor-hover');
    const { pageX: posX, pageY: posY } = e;
  
    const runMouseOver = () => {
      CURSOR.style.border = '30px #EB6C6C solid';
      CURSOR.style.opacity = '1';
    };
    HOVER.forEach(hover => hover.addEventListener('mouseenter', runMouseOver));
  
    const runMouseLeave = () => {
      CURSOR.style.transform = '';
      CURSOR.style.background = '';
      CURSOR.style.border = '2px #EB6C6C solid';
      CURSOR.style.opacity = '.8';

    };
    HOVER.forEach(hover => hover.addEventListener('mouseleave', runMouseLeave));
    
    return (
      CURSOR.style.transform = `translate(${posX - 25}px, ${posY - 25}px)`
    );
    
  };
  
  return(
      <div className="mouse-cursor"></div>
  )
}

const Wrapper = (props) =>{
  return(
    <div
    className="main__layout"
      style={{
        width: `100%`,
        margin: `0 auto`,
        // padding: `1rem 2rem`,
      }}
    >
      { props.children }
    </div>
  )
}

const Footer = () => {
  return (
    <footer className="global__footer in--from--below">
        <a href="https://www.linkedin.com/in/riwat-rai" target="_blank" rel="noopener noreferrer"><img className="global__footer__icon__linkedin" src={LinkedinIcon} alt="email icon"/></a>
         <p className="global__footer__copyright">&copy;{new Date().getFullYear()} Riwat Rai</p>
    </footer>
  )
}

const Layout = (props) => {

  // console.log(props)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Cursor></Cursor>
      <Wrapper>
        <Navbar className="nav__bar" navState="test" siteTitle="Riwat Rai's porfolio" />
          <main>{props.children}</main>
        <Footer/>
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
