import React, {useState} from "react";
import { Link } from "gatsby";

const activated = ({isCurrent}) =>{
        return isCurrent ? {className: "nav__active"} : null;
}

const NavItem = ({navItem, navstate}) =>{
    const {id,name,slug} = navItem;
    return (
                    <li className={`cursor-hover ${navstate ? 'nav--link--txt--in' : 'nav--link--txt--out'}`}  key={ id } >
                        <Link className="a-test" name="link" getProps={activated} to={slug}><span className="link__number">0{id}</span>{name}</Link>
                        </li>
    )
}

export default NavItem