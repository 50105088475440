// import { Link,useStaticQuery,graphql } from "gatsby"
import React, {useState} from "react"
import { StaticQuery, graphql, Link} from "gatsby"
import { motion, AnimatePresence } from 'framer-motion'
import NavItem from './NavItem'
import Logo from "../../images/rai-logo.svg"

const Navbar = ({ navItems }) => {
  
  
  const variants = {
    open: { 
      opacity: 1, 
      y:'0%',
      transition:{
        bounceStiffness:0,
        bounceDamping:10,
        duration:.60
      }
    },
    closed: { 
      opacity: 0, 
      y:'-100%',
      transition:{
        bounceStiffness:0,
        bounceDamping:10,
        duration:.35
      }
    },
  }
  const [navstate, setNavstate] = useState(false);
  
  return (
    
    <nav className="header__container">
          <div className="header__logo">
            <Link className="header__logo__link" to="/"><img className="header__logo__link__svg" src={Logo} alt="main logo svg"></img>
              <h4 className="header__logo__link__txt"><span className="header__logo__link__txt--bold">Rai</span><br/>Devsign</h4>
            </Link>
          </div>
          <div className="navigation__desktop">
            <ul className="navigation__desktop__list">
              {navItems.map( navItem =><NavItem key={navItem.id} navItem={navItem} />)}
            </ul>  
          </div>  
          <div className="navigation__mobile">
            <div className="navigation__mobile__toggle">
              <input className="navigation__mobile__toggle__checkbox" type="checkbox" onClick={() => setNavstate(!navstate)}/>
              <div className="navigation__mobile__toggle__icon">
                <span className="navigation__mobile__toggle__icon__top"></span>
                <span className="navigation__mobile__toggle__icon__mid"></span>
                <span className="navigation__mobile__toggle__icon__bottom"></span>
              </div>
            </div>
            <AnimatePresence>
                  <motion.nav initial={{opacity:0}} animate={navstate ? "open" : "closed"} variants={variants}  className="navigation__mobile__menu">
                    <ul >
                        {navItems.map( navItem =><NavItem key={navItem.id} navItem={navItem} navstate={navstate}/>)}
                    </ul>
                  </motion.nav>
              </AnimatePresence>
          </div>
            <div className={`nav__animation__layer ${navstate ? "animation--layer--in" :"animation--layer--out" }`}></div>
    </nav>
    ) 
  }
  
  export default props =>(
    <StaticQuery
    query = {query}
    render = {data => <Navbar navItems={data.site.siteMetadata.navitem}  {...props}/>}
    />
  )
  const query = graphql`
  query NavItemsQuery{
    site {
      siteMetadata{
        navitem{
          id
          name
          slug
        }
      }
    }
  }`
  
  // export default Navbar
  // {navItems.map( navItem => 
  //   <NavItem navItem={navItem}/>
  //   )}